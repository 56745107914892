<script setup lang="ts">
import { ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms/use-cms'

const props = withDefaults(
  defineProps<{
    type?: 'success' | 'warning' | 'error'
    icon?: string
    iconSize?: number
    title?: string
    text?: string
    showCancelButton?: boolean
    confirmButtonText?: string
    cancelButtonText?: string
    onConfirm?: () => void
    onCancel?: () => void
    analytics?: {
      confirm: Record<string, unknown>
      cancel: Record<string, unknown>
    }
  }>(),
  {
    type: 'error',
    iconSize: 48,
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
  }
)

const emit = defineEmits<(event: 'hide') => void>()

const { isDarkThemeForEwallet } = useCms()

const isConfirming = ref(false)

function hide() {
  emit('hide')
}

async function onConfirmClicked() {
  if (props.onConfirm) {
    isConfirming.value = true

    await props.onConfirm()

    isConfirming.value = false
  }

  hide()
}

async function onCancelClicked() {
  if (props.onCancel) {
    await props.onCancel()
  }

  hide()
}
</script>

<template>
  <div
    class="px-5 pt-5"
    :class="{
      'bg-gray-900 text-white': isDarkThemeForEwallet,
    }"
  >
    <div class="absolute right-5 top-5">
      <base-button
        icon="heroicons/solid/x-mark"
        :size="28"
        @click="onCancelClicked"
      />
    </div>
    <slot name="icon">
      <div v-if="icon" class="flex justify-center">
        <base-icon
          :svg="icon"
          :size="iconSize"
          :class="{
            'text-red-700': type === 'error',
            'text-orange-700': type === 'warning',
            'text-emerald-700': type === 'success',
          }"
        />
      </div>
    </slot>
    <slot name="title">
      <div
        class="mb-10 mt-4 text-center text-xl font-bold"
        :class="{
          'text-eonx-neutral-800': !isDarkThemeForEwallet,
          'text-white': isDarkThemeForEwallet,
        }"
      >
        {{ props.title }}
      </div>
    </slot>
    <slot name="text">
      <div
        class="mb-5 whitespace-pre-line text-base font-normal leading-6"
        :class="{
          'text-eonx-neutral-600': !isDarkThemeForEwallet,
          'text-white': isDarkThemeForEwallet,
        }"
      >
        {{ props.text }}
      </div>
    </slot>
    <hr class="-mx-5" />
    <div class="grid w-full grid-cols-2 gap-x-2.5 py-5">
      <base-button
        v-if="props.showCancelButton"
        v-analytics:click="props.analytics?.cancel"
        look="outlined-color"
        class="h-12 w-full"
        :disabled="isConfirming"
        @click="onCancelClicked"
      >
        {{ props.cancelButtonText }}
      </base-button>
      <base-button
        v-analytics:click="props.analytics?.confirm"
        :full-width="true"
        :loading="isConfirming"
        :disabled="isConfirming"
        @click="onConfirmClicked"
      >
        {{ props.confirmButtonText }}
      </base-button>
    </div>
  </div>
</template>
